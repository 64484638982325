import { graphql } from 'gatsby';

import { PageWrapper } from 'components/Layout/PageWrapper';
import { HeadingSmall, SubTitle } from 'components/UI/Headings';
import { CardsContainer, MainContainer } from 'components/UI/Containers';
import { Navigator } from 'components/Layout/Navigator';
import { DarkButton } from 'components/UI/Buttons';

import Icon from 'assets/icons/arrow.svg';

import Card from 'components/Card';
import Image from 'components/Image';

import BankIcon from 'assets/icons/bank.svg';

import { ButtonWrapper, Info, Cards, TeacherPhoto } from './styles';

const TeacherTemplate = ({
  data: {
    datoCmsTeacher: pageData,
    datoCmsMiscTextString: translations,
    datoCmsHomePage: { homepageId },
  },
  pageContext,
}) => {
  const {
    teacherPhoto,
    fullName,
    about,
    position,
    topics,
    awards,
    achievements,
  } = pageData;
  const { positionTitle, topicsTitle, awardsTitle, achievementsTitle } =
    translations;

  return (
    <PageWrapper pageData={pageContext}>
      <MainContainer>
        <section>
          <ButtonWrapper>
            <DarkButton as={Navigator} recordId={homepageId}>
              <Icon />
              Назад
            </DarkButton>
          </ButtonWrapper>
          <Info>
            {teacherPhoto && (
              <TeacherPhoto>
                <Image image={teacherPhoto} alt={fullName} />
              </TeacherPhoto>
            )}
            <HeadingSmall>{fullName}</HeadingSmall>
            <SubTitle>{about}</SubTitle>
          </Info>
          <Cards as={CardsContainer}>
            <Card
              icon={<BankIcon />}
              title={positionTitle}
              description={position}
              align="center"
            />
            <Card
              icon={<BankIcon />}
              title={topicsTitle}
              description={topics}
              align="center"
            />
            <Card
              icon={<BankIcon />}
              title={awardsTitle}
              description={awards}
              align="center"
            />
          </Cards>
          <Card title={achievementsTitle} description={achievements} />
        </section>
      </MainContainer>
    </PageWrapper>
  );
};

export default TeacherTemplate;

export const query = graphql`
  query TeacherQuery($locale: String!, $id: String!) {
    datoCmsTeacher(locale: { eq: $locale }, originalId: { eq: $id }) {
      teacherPhoto {
        gatsbyImageData
      }
      fullName
      about
      position: teacherPosition
      topics: topicsOfLectures
      awards
      achievements: optionalAchievements
    }
    datoCmsHomePage(locale: { eq: $locale }) {
      homepageId: originalId
    }
    datoCmsMiscTextString(locale: { eq: $locale }) {
      positionTitle: teacherPositionTitle
      topicsTitle: teacherTopicsTitle
      awardsTitle: teacherAwardsTitle
      achievementsTitle: teacherAchievementsTitle
    }
  }
`;
