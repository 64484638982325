import styled from 'styled-components';

import { SubTitle } from 'components/UI/Headings';

import { media } from 'styles/mixins';

const ButtonWrapper = styled.div`
  width: fit-content;
`;

const Info = styled.div`
  margin-bottom: 4.5rem;
  text-align: center;

  ${media.mobileM`
    margin-bottom: 2rem;
  `}

  ${SubTitle} {
    padding-top: 0;
  }
`;

const Cards = styled.div`
  margin: 0 0 1.5rem;
`;

const TeacherPhoto = styled.div`
  margin: 0 auto 1rem;
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  height: 8.5rem;
  width: 8.5rem;

  & > div {
    height: 100%;
  }
`;

export { ButtonWrapper, Info, Cards, TeacherPhoto };
